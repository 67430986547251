<script>

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { required,email } from "vuelidate/lib/validators";
import moment from 'moment-timezone';

import {
  campaignMethods,
  userMethods,
  availabilityMethods
} from "@/state/helpers";

/**
 * New event component
 */
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      submitted:false,
      campaigns:[],
      hosts:[],
      date:null,
      time:null,
      availabilities:[],
      availability:null,
      showAvailability: false,
      minDate: new Date(),
      timeError: true
    };
  },
  validations: {
    event: {
      title: { required },
      campaign: { required },
      user_name:{required},
      user_email:{required,email},

      host: { required },
      date: { required },
      duration:{required},
    },
  },
  methods: {
    ...campaignMethods,
    ...userMethods,
    ...availabilityMethods,
    
    formatDateQuery(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
        return [year, month, day].join('-');
    },

    parseDate(objDate) {
      let month = '' + (objDate.getMonth() + 1),
      day = '' + objDate.getDate(),
      year = objDate.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    }, 

    onCreateOrEditEventClicked() {
      this.submitted = true;

      this.$v.$touch();
      
      if (this.$v.$invalid || this.timeError) {
        return;
      } 

      if(this.showAvailability){

        let loader = this.$loading.show();

        let dateStr = this.parseDate(new Date(this.event.date)) + " " + this.event.time;
        let date = new Date(dateStr).toISOString();

        const payload = {
          campaign: this.event.campaign._id,
          host: this.event.host._id,
          fixedHost: true,
          date
        }
        
        this.validateTimeSlotAvailability(payload).then((res)=>{

          if(res.isValid){
            if(this.event._id) {
              this.$emit('updateEvent');
            }else{
              this.$emit('createEvent');
            }
          }else{
            this.$notify({ type: 'error', text: this.$t("events.validate_slot_error"),title: this.$t("events.new_event")});
            this.availability = null;
            this.getTimeAvailabilities();
          }
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("events.new_event_error"),title:  this.$t("events.new_event") });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        if(this.event._id) {
          this.$emit('updateEvent');
        }else{
          this.$emit('createEvent');
        }
      }

      
      
    },

    loadCampaigns(){
      let loader = this.$loading.show();

      const params = {q : `whereObj={"project":"${localStorage.getItem('current_project')}","endDate" : {"$gte" : "${this.formatDateQuery(new Date())}"} }` }

      this.getCampaigns(params).then((campaigns)=>{
        if(campaigns&& campaigns.data){
          this.campaigns= campaigns.data;
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("campaigns.get_campaigns_error"),title:  this.$t("campaigns.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    onCampaignSelected(){
      this.hosts = [];
      if(this.event.campaign){
        this.loadHostsByCampaign(this.event.campaign);
        this.event.duration = this.event.campaign.durationHour* 60 + this.event.campaign.durationMinutes;
        this.showAvailability = this.event.campaign.availability ? true : false;

        this.getTimeAvailabilities();
      }else{
        this.showAvailability = false;
        this.availability = null;
      } 
    },

    loadHostsByCampaign(campaign){
      let loader = this.$loading.show();
      const params={
				q: `where[role]=host&where[group]=${campaign.group}`,
			}
			this.getUsers(params).then((users)=>{
				if(users&& users.data){
					this.hosts= users.data;
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
    },

    getTimeAvailabilities(){
      const nowDate = new Date();
      this.availabilities = [];
      
      if(this.event.campaign && this.event.campaign.availability && this.date){
        let loader = null;
        if(!this.event._id){
          loader = this.$loading.show();
        }
          
        const params = {
           q: this.event.host?`campaign=${this.event.campaign._id}&day=${moment(this.date).format('YYYY-MM-DD')}&host=${this.event.host._id}`: `campaign=${this.event.campaign._id}&day=${moment(this.date).format('YYYY-MM-DD')}`
        }
        this.getTimeSlotsByAvailability(params).then((res)=>{
          
          if(res){

            for (var i = 0; i < res.slots.length; i++) {
              
              const availability = res.slots[i];
              if(moment(nowDate)<= moment(availability.start)) {
                availability.name = moment(availability.start).format('hh:mm A');
                this.availabilities.push(availability);
              }
            }
          }
        }).catch(()=>{
          this.showAvailability = false;
        }).finally(()=>{
          if(!this.event._id){
            loader.hide();
          }
        })
      }
    },
    
    dateChanged(){
      this.event.date= this.date;
      this.getTimeAvailabilities();
    },

    timeChanged(){
      this.event.time= this.time; 
      this.timeError = this.event.time == null
    },

    datepickerDisabledInPast(date){
      const today = new Date()
        today.setHours(0, 0, 0, 0)

        return date < today
    },

    onAvailabilitySelected(){
      if(this.availability){
        this.event.time = moment(this.availability.start).format('hh:mm A');
      }
      this.timeError = this.event.time == null
      
    },

    onHostSelected(){
      if(!this.event._id)
        this.getTimeAvailabilities();
    },

    onUseAvailabilityChecked(){
      this.time = null;
      this.event.time = null;
      this.availability = null;

      this.timeError = this.event.time == null
    },
  },



  mounted() {
    this.loadCampaigns();
    
    if(JSON.parse(localStorage.getItem('auth.currentUser')).user.role=='host') {
      this.event.host = JSON.parse(localStorage.getItem('auth.currentUser')).user;
    }
    if(this.event._id) {
      this.date = this.event.date;
      this.time = this.event.time;
      this.showAvailability = this.event.campaign.availability ? true : false;
      
      this.hosts = [];
      if(this.event.campaign){
        this.loadHostsByCampaign(this.event.campaign);
        this.getTimeAvailabilities();
      }
      
      
    }
    
    if(this.eventInfo && this.eventInfo.date){
      this.date= new Date(this.eventInfo.date);
      this.dateChanged();
    }



  },
  props: {
    eventInfo: {
      type: Object,
    },
    showHost: {
      type: Boolean,
    },
    showDate: {
      type: Boolean,
    },
    event:{
      type: Object,
    },
    eventId:{
      type: String,
    },

  },
  emits: ['createEvent','updateEvent','closeCreateEventModal']
  
};
</script>

<template>
    <div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label for="title">{{$t('events.new_event_title')}}</label>
          <input
            id="title"
            v-model="event.title"
            type="text"
            class="form-control"
            :placeholder="$t('events.new_event_title_placeholder')"
            :class="{ 'is-invalid': submitted && $v.event.title.$error }"
          />
          <div v-if="submitted && !$v.event.title.required" class="invalid-feedback">
            {{$t('events.new_event_title_required')}}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="control-label">{{$t('events.new_event_campaign')}}</label>
          <multiselect id="campaign"
              v-model="event.campaign"
              :options="campaigns"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :placeholder="$t('events.new_event_campaign_placeholder')"
              :multiple="false"
              :class="{'is-invalid': submitted && $v.event.campaign.$error,}"
              track-by="name" 
              label="name"
              :showNoResults="false"
              @input="onCampaignSelected">
          </multiselect>
          <div v-if="submitted && !$v.event.campaign.required" class="invalid-feedback">
            {{$t('events.new_event_campaign_required')}}
          </div>
        </div>
      </div>
      <div class="col-12" v-if="showHost && !event._id">
        <div class="mb-3">
          <label class="control-label">{{$t('events.new_event_host')}}</label>
          <multiselect 
              v-model="event.host"
              :options="hosts"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :placeholder="$t('events.new_event_host_placeholder')"
              :multiple="false"
              :class="{'is-invalid': submitted && $v.event.host.$error,}"
              track-by="name" 
              label="name"
              :showNoResults="false"
              @input="onHostSelected">
          </multiselect>
          <div v-if="submitted && !$v.event.host.required" class="invalid-feedback">
            {{$t('events.new_event_host_required')}}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label>{{$t('events.new_event_date')}}</label>
        <date-picker
          ref="date"
          v-model="date"
          :first-day-of-week="1"
          :placeholder="$t('events.new_event_date_placeholder')"
          :class="{'is-invalid': submitted && $v.event.date.$error,}"
          @input="dateChanged"
          :disabled-date="datepickerDisabledInPast" 
          :disabled-calendar-changer="datepickerDisabledInPast">
        </date-picker>
        <div v-if="submitted && !$v.event.date.required"  class="invalid-feedback">
          {{$t('events.new_event_date_required')}}
        </div>
      </div>
      <div class="col-12" v-if="date">
          <div class="mb-3">
              <b-form-checkbox
                v-model="showAvailability"
                class="mb-3"
                checked
                plain
                @change="onUseAvailabilityChecked">
                {{$t("events.new_event_use_slots")}}
              </b-form-checkbox>
          </div>
        </div>
      <div class="col-12" v-if="showAvailability && date">
        <div class="mb-3">
          <label class="control-label">{{ event._id ? $t('events.new_event_new_hour') :   $t('events.new_event_hour')}}</label>
          <multiselect id="availabilities"
              v-model="availability"
              :options="availabilities"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :placeholder="$t('events.new_event_hour_placeholder')"
              :multiple="false"
              track-by="name" 
              label="name"
              :showNoResults="false"
              :class="{'is-invalid': submitted && timeError}"
              @input="onAvailabilitySelected"
              open-direction="bottom">
          </multiselect>
          <div v-if="submitted && timeError" class="invalid-feedback">
            {{$t('events.new_event_hour_required')}}
          </div>
        </div>
      </div>
      <div class="mb-3 col-sm-8" v-if="!showAvailability && date">
        <label>{{$t('events.new_event_hour')}}</label>
        <date-picker
          v-model="time"
          format="hh:mm a"
          value-type="format"
          type="time"
          :placeholder="$t('events.new_event_hour_placeholder')"
          :class="{'is-invalid': submitted && timeError}"
          @input="timeChanged"
        ></date-picker>
        <div v-if="submitted && timeError" class="invalid-feedback">
          {{$t('events.new_event_hour_required')}}
        </div>
      </div>

      <div class="mb-3 col-sm-4" v-if="!showAvailability && date">
        <label>{{$t('events.new_event_duration')}}</label>
        <div class="form-group">
          <b-form-input
            v-model="event.duration"
            type="number"
            name="number"
            :class="{'is-invalid': submitted && $v.event.duration.$error,}">
          </b-form-input>
        </div>
        <div v-if="submitted && !$v.event.duration.required" class="invalid-feedback">
          {{$t('events.new_event_duration_required')}}
        </div>
      </div>
      <h5> {{$t('events.new_event_invite_data')}} </h5>
      <div class="mb-3">
        <label for="name">{{$t('users.name')}}</label>
        <input
          id="name"
          v-model="event.user_name"
          type="text"
          class="form-control"
          :placeholder="$t('users.name_placeholder')"
          :class="{'is-invalid':submitted && $v.event.user_name.$error,}"
        />
        <div v-if="submitted && !$v.event.user_name.required" class="invalid-feedback">
            {{$t('users.name_required')}}
        </div>
      </div>
      <div class="mb-3">
        <label for="email">{{$t('users.email')}}</label>
        <input
          id="email"
          v-model="event.user_email"
          type="email"
          class="form-control"
          :placeholder="$t('users.email_placeholder')"
          :class="{'is-invalid':submitted && $v.event.user_email.$error,}"
        />
        <div v-if="submitted && !$v.event.user_email.required" class="invalid-feedback">
          {{$t('users.email_required')}}
        </div>
      </div>
      <div class="mb-3">
        <label for="phone">{{$t('events.new_event_phone')}}</label>
        <input
          id="phone"
          v-model="event.user_tel"
          type="tel"
          class="form-control"
          :placeholder="$t('events.new_event_phone_placeholder')"
        />
      </div>
    </div>
    <div class="text-end pt-5 mt-3">
      <b-button variant="light" @click="$emit('closeCreateEventModal')">{{$t('common.cancel')}}</b-button>
      <b-button variant="primary" class="ms-1" @click="onCreateOrEditEventClicked">{{$t('common.confirm')}}</b-button>
    </div>
  </div>
</template>