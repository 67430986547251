<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import esLocale from "@fullcalendar/core/locales/es";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import NewEventModal from "@/components/events/new-event";
import NewShowEventModal from "@/components/events/new-show";
import FilterEvents from "@/components/events/filter";
import EventDetailModal from "@/components/events/event-detail";
import EventShowDetailModal from "@/components/events/show-detail";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import moment from 'moment-timezone';
import Drawer from "vue-simple-drawer";


import {
	eventMethods,
} from "@/state/helpers";

/**
 * Calendar component
 */
export default {
  page: { contentHeight: 'auto',
    title: "Calendar",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    FullCalendar,
    Layout,
    PageHeader,
    NewEventModal,
    EventDetailModal,
    FilterEvents,
    Multiselect,
    NewShowEventModal,
    EventShowDetailModal,
    Drawer
  },
  data() {
    return {
      title: "",
      items: [],
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: JSON.parse(localStorage.getItem("auth.currentUser")).user.role && JSON.parse(localStorage.getItem("auth.currentUser")).user.role == "admin"? "dayGridMonth": "listWeek",
        themeSystem: "bootstrap",
        initialEvents: this.events,
        editable: false,
        droppable: true,
        eventResizableFromStart: false,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        eventMouseEnter: this.handleMouseEnter,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        locale:((this.$i18n.locale === 'es') ? esLocale : null),
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false
        },
        contentHeight: 'auto',
        datesSet: this.handleMonthChange,
      },

      showModalCreateEvent:false,
      showModalEventDetail:false,
      showModalAssignEvent:false,
      confirmDelete: false,
      newEventData: {},
      showDate: true,
      showHost : JSON.parse(localStorage.getItem("auth.currentUser")).user.role && JSON.parse(localStorage.getItem("auth.currentUser")).user.role == "admin",
      events:[],
      event:{
        duration : 30
      },
      currentEvents: [],
      eventToEdit: {},
      calendarKey:"",
      startCalendarDate:'',
      endCalendarDate:'',
      filterVisible: false,
      query:'',
      countFilter: 0,
      hostsAvailable:[],
      selectedHost:null,
      showNewShowModal: false,
      showModalShowEventDetail:false,
      openRightDrawer: false,
      filters:[]
    };
  },
  mounted(){
    //this.loadEvents()

  },
  methods: {
    ...eventMethods,

    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key == 'guest'){
            q+= `,"$or": [{"invites.name" : {"$regex" : ".*${f.value}*." , "$options": "i"} }, {"invites.email" : {"$regex" : ".*${f.value}*." , "$options": "i"}} ]`
          }else{
            q+= `, "${f.key}": "${f.value}"`
          }
          
        });
      }

      return q;
    },
    applyCustomFilter(){
      
      this.filters = this.$refs.filterRef.getAllFilters();
      
     this.applyFilter({})
     
      this.openRightDrawer = false;
    },
		onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      
      this.applyFilter()
      
    },
		onFilterClicked(){
      this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
    },
    
    removeAllFilter(){
      this.$refs.filterRef.removeAll();
    },

    rightDrawer(){
      this.openRightDrawer = false;
    },

    eventToEventModel(event){

      let dateStr = this.parseDate(new Date(event.date)) + " " + event.time;
      let date = new Date(dateStr).toISOString();
      let endDate = this.addMinutes(event.duration,new Date(dateStr)).toISOString();

      let eventModel = {
        _id: event._id,
        title: event.title,
        date,
        endDate,
        selectedProducts:[],
        durationHour: Math.trunc(event.duration / 60),
        durationMinutes: event.duration - (Math.trunc(event.duration/60) * 60),
        campaign: event.campaign,
        host: event.host,
        invites: event.type && event.type == 'ONE_TO_MANY' ? null : [
            {
              "name" : event.user_name,
              "email" : event.user_email,
              "tel": event.user_tel
            }
          ]
      }

      return eventModel;
    },

    addMinutes(numOfMinutes, date) {
      date.setTime(date.getTime() + numOfMinutes  * 60 * 1000);
      return date;
    },

    eventFromCalendarEvent(calendarEvent){
      
      this.event._id = calendarEvent.extendedProps.eventInfo._id;
      this.event.title = calendarEvent.extendedProps.eventInfo.title;
      this.event.campaign = calendarEvent.extendedProps.eventInfo.campaign;
      this.event.host = calendarEvent.extendedProps.eventInfo.host;
      this.event.date = new Date(calendarEvent.extendedProps.eventInfo.date);
      this.event.time = this.parseTime(new Date(calendarEvent.extendedProps.eventInfo.date));
      this.event.duration  = calendarEvent.extendedProps.eventInfo.durationHour * 60  + calendarEvent.extendedProps.eventInfo.durationMinutes
      this.event.endDate = this.addMinutes(this.event.duration,new Date(calendarEvent.extendedProps.eventInfo.date))
      this.event.url = calendarEvent.extendedProps.eventInfo.url;
      this.event.urlHost = calendarEvent.extendedProps.eventInfo.urlHost;
      this.event.user_name = calendarEvent.extendedProps.eventInfo.invites?.length ? calendarEvent.extendedProps.eventInfo.invites[0].name : '';
      this.event.user_email = calendarEvent.extendedProps.eventInfo.invites?.length ? calendarEvent.extendedProps.eventInfo.invites[0].email : '';
      this.event.user_tel = calendarEvent.extendedProps.eventInfo.invites?.length ? calendarEvent.extendedProps.eventInfo.invites[0].tel : '';
      this.event.status = calendarEvent.extendedProps.eventInfo.status;
    },

    loadHostsAvailable(){
      this.hostsAvailable = [];

      let loader = this.$loading.show();

      this.getHostsAvailable(this.event._id).then((res)=>{
        this.hostsAvailable = res.hosts;
      }).catch(()=>{
        
      }).finally(()=>{
        loader.hide();
      });
    },

    loadEvents(){
      this.events = [];
      const calendarApi = this.$refs.fullCalendar.getApi();
      
      calendarApi.removeAllEvents();

      let loader = this.$loading.show();

      if(this.filters.length > 0){
        this.query = this.getCustomFilterQuery();
      }
      
      const params={
				q: `whereObj={"project":"${localStorage.getItem("current_project")}","date":{"$gte": "${this.startCalendarDate}", "$lte": "${this.endCalendarDate}"} ${this.query}}&limit=1000&populate[0][path]=host&populate[0][select]=_id name&populate[1][path]=campaign&populate[1][select]=_id name group availability project`,
			}

      
			this.getEvents(params).then((events)=>{
        this.isLoading = false;
				loader.hide();

        if(events && events.data){
          //let id=1;

          const evs = events.data.map((e, index) => ({
            id: index + 1,
            title: e.title,
            start: e.date,
            end: e.endDate,
            eventInfo: e,
            classNames: e.type && e.type == 'ONE_TO_MANY' ? "bg-info text-white text-pre-wrap" :  e.status == 'canceled' ?  "bg-success text-white text-decoration-line-through text-pre-wrap" : "bg-success text-white text-pre-wrap",
          }));

          calendarApi.addEventSource(evs);
          /*events.data.forEach(e=>{
            let event= {
              id,
              title:e.title,
              start:e.date,
              end:e.endDate,
              eventInfo:e,
              classNames: e.type && e.type == 'ONE_TO_MANY' ? "bg-info text-white text-pre-wrap" :  e.status == 'canceled' ?  "bg-success text-white text-decoration-line-through text-pre-wrap" : "bg-success text-white text-pre-wrap"
            }

            this.events.push(event);
            // Add new event
            
            calendarApi.addEvent(event, false);

            id++;
          })

          this.calendarOptions.events = this.events;
          */
          

          }
			}).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("events.get_events_error"),title:  this.$t("events.title") });
			}).finally(()=>{
				
			});
    },

    onCreateEventClicked() { 
      this.showDate = true;
      this.showModalCreateEvent = true
      this.event={
        duration : 60
      };
      this.newEventData = {}
    },

    /**
     * Modal form submit
     */
    
    parseTime(objDate) {
      let hour = objDate.getHours();
      let minutes = objDate.getMinutes();

      var ampm = hour >= 12 ? 'pm' : 'am';

      hour = hour >=12 ? hour - 12 : hour;

      if (hour.toString().length < 2) 
        hour = '0' + hour;

      if (minutes.toString().length < 2) 
        minutes = '0' + minutes;

      return hour+":"+minutes +" " + ampm;
      
    },

    parseDate(objDate) {
      let month = '' + (objDate.getMonth() + 1),
      day = '' + objDate.getDate(),
      year = objDate.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    }, 

    handleUpdateEvent(){

      let loader = this.$loading.show();
      let payload= this.eventToEventModel(this.event);
      
      this.updateEvent(payload).then((result)=>{
        if(!result.data)
          throw new Error();

        this.successmsg(this.$t("events.edit_event_success"));
        this.loadEvents();
        this.showModalCreateEvent = false;
        this.showModalEventDetail = false;
        this.eventToEdit = {};
        this.event = {};
        this.newEventData = {};

      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("events.edit_event_error"),title:  this.$t("events.update_event") });
      }).finally(()=>{
        loader.hide();
      });
    },

    handleCreateEvent() {
      
      let dateStr = this.parseDate(new Date(this.event.date)) + " " + this.event.time;
      let date = new Date(dateStr).toISOString();

      let loader = this.$loading.show();
      
      let payload = {
        title: this.event.title,
        date,
        selectedProducts:[],
        durationHour: Math.trunc(this.event.duration / 60),
        durationMinutes: this.event.duration - (Math.trunc(this.event.duration/60) * 60),
        campaign: this.event.campaign,
        host: this.event.host,
        invites: [
            {
              "name" : this.event.user_name,
              "email" : this.event.user_email,
              "tel": this.event.user_tel
            }
          ]
      }
      
      this.createEvent(payload).then(()=>{
          
          this.successmsg(this.$t("events.new_event_success"));

          this.loadEvents();
          this.showModalCreateEvent = false;
          this.showNewShowModal = false;
          this.event = {};
          this.newEventData = {};
          
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("events.new_event_error"),title:  this.$t("events.new_event") });
			}).finally(()=>{
				loader.hide();
			});
    },
    
    hideEventDetailModal() {
      this.showModalEventDetail = false;
      this.event= {}
      this.edit = {}
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModalCreateEvent = false;
      this.showModalEventDetail = false;
      this.event = {};
    },

    removeEventClicked() {
      this.confirmDelete=true;
    },

    onConfirmRemoveEventClicked(){
     //this.deleteAndRemoveEvent(); 

     this.onCancelEvent();
    },
    
    /**
     * Delete event
     */
    deleteAndRemoveEvent() {
      this.confirmDelete=false;
      let loader = this.$loading.show();
      
      this.deleteEvent(this.eventToEdit.event.extendedProps.eventInfo._id).then(()=>{
        this.showModalEventDetail = false;
        this.successmsg(this.$t("events.delete_event_success"));
        this.eventToEdit.event.remove();
        
        this.loadEvents();
      }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("events.delete_event_error"),title:  this.$t("events.delete_event") });
      }).finally(()=>{
        loader.hide();
      });
    },

    onCancelEvent(){

      this.confirmDelete=false;
      let loader = this.$loading.show();

      const toUpdate = { _id: this.eventToEdit.event.extendedProps.eventInfo._id,  status: "canceled", realEndDate : new Date()};
            this.updateEvent(toUpdate).then(()=>{
              this.showModalEventDetail = false;
              this.successmsg(this.$t("events.delete_event_success"));
              this.eventToEdit.event.remove();
              
              this.loadEvents();

            }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t("events.delete_event_error"),title:  this.$t("events.delete_event") });
                
            }).finally(()=>{
              loader.hide();
            })
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      
      if(moment(new Date())<= moment(info.date).endOf('day')) {
        this.event = {};
        this.newEventData = info;
        this.showModalCreateEvent = true;
      }
      
    },
    
    editEvent(info) {
      if(info.event.extendedProps.eventInfo.type && info.event.extendedProps.eventInfo.type == 'ONE_TO_MANY'){
        this.showModalShowEventDetail = true;
      }else{
        this.showModalEventDetail = true;
      }
      
      this.eventToEdit = info;
      this.eventFromCalendarEvent(this.eventToEdit.event)
    },

    handleEvents(events) {
      this.currentEvents = events;
    },

    editEventClicked() {
      this.showModalCreateEvent = true;
    },

    assignEventClicked() {
      this.showModalEventDetail = false;
      this.showModalAssignEvent = true;
      this.hostsAvailable = [];

      this.loadHostsAvailable();
    },
    /**
     * Show successfull Save Dialog
     */
    successmsg(title) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async handleMonthChange(payload) {

      if(this.parseDate(new Date(payload.startStr)) != this.startCalendarDate && this.parseDate(new Date(payload.endStr))!= this.endCalendarDate){
        this.startCalendarDate = this.parseDate(new Date(payload.startStr));
        this.endCalendarDate = this.parseDate(new Date(payload.endStr));

        this.loadEvents();
      }
    },

    applyFilter(queryObj){
      this.query = queryObj?.query || '';
      
      this.loadEvents();
    },
    handleMouseEnter(info){
      
      if(!info.event.extendedProps.eventInfo.type){
        const title = `📞 ${info.event.title}
👤 ${info.event.extendedProps.eventInfo.invites[0].name}
🗓️ ${info.event.extendedProps.eventInfo.host.name}
🪧 ${this.getStatusText(info.event.extendedProps.eventInfo.status)}`
      
      //info.el['v-b-popover.hover.top']= title
      info.el.title= title;
      }

      
      
    },
    getStatusText(status){
      if(status=='scheduled'){
        return this.$t('events_list.status_scheduled');
      }else if(status=='closed'){
        return this.$t('events_list.status_closed');
      }else if(status=='started'){
        return this.$t('events_list.status_started');
      }else if(status=='canceled'){
        return this.$t('events_list.status_canceled');
      }

      return status;
    },

    handleAssignEventClicked(){

      if(this.event && this.selectedHost){
        let loader = this.$loading.show();
        const payload = {
          _id: this.event._id,
          host: this.selectedHost._id
        }
        this.updateEvent(payload).then((result)=>{
          if(!result.data)
            throw new Error();

          this.successmsg(this.$t("events.edit_event_success"));
          this.loadEvents();
          this.showModalCreateEvent = false;
          this.showModalEventDetail = false;
          this.showModalEventDetail = false;
          this.showModalAssignEvent = false;
          this.eventToEdit = {};
          this.event = {};
          this.newEventData = {};

        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("events.edit_event_error"),title:  this.$t("events.update_event") });
        }).finally(()=>{
          loader.hide();
        });
      }else{
        this.showModalAssignEvent = false;
      }
      
    },
    
    onCreateNewShowClicked(){
      this.showDate = true;
      this.event={
        duration : 60,
        type: 'ONE_TO_MANY'
      };
      this.newEventData = {}
      this.showNewShowModal = true;
    },

    handleCreateShowEvent(){

      let dateStr = this.parseDate(new Date(this.event.date)) + " " + this.event.time;
      let date = new Date(dateStr).toISOString();

      let loader = this.$loading.show();
      
      let payload = {
        title: this.event.title,
        date,
        selectedProducts:[],
        durationHour: Math.trunc(this.event.duration / 60),
        durationMinutes: this.event.duration - (Math.trunc(this.event.duration/60) * 60),
        campaign: this.event.campaign,
        host: this.event.host,
        type: 'ONE_TO_MANY',
        status:'scheduled'
      }
      
      this.createShowEvent(payload).then(()=>{
          
          this.successmsg(this.$t("events.new_event_success"));

          this.loadEvents();
          this.showNewShowModal = false;
          this.event = {};
          this.newEventData = {};
          
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("events.new_event_error"),title:  this.$t("events.new_event") });
			}).finally(()=>{
				loader.hide();
			});
    },
    hideEventShowDetailModal() {
      this.showModalShowEventDetail = false;
      this.event= {}
      this.edit = {}
    },
    
    hideShowModal(){
      this.submitted = false;
      this.showModalShowEventDetail = false;
      this.showNewShowModal = false;
      this.event = {};
    },
    editEventShowClicked(){
      this.showNewShowModal = true;
    },

    handleUpdateShowEvent(){

      let loader = this.$loading.show();
      let payload= this.eventToEventModel(this.event);

      this.updateEvent(payload).then((result)=>{
        if(!result.data)
          throw new Error();

        this.successmsg(this.$t("events.edit_event_success"));
        this.loadEvents();
        this.showNewShowModal = false;
        this.showModalShowEventDetail = false;
        this.eventToEdit = {};
        this.event = {};
        this.newEventData = {};

      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("events.edit_event_error"),title:  this.$t("events.update_event") });
      }).finally(()=>{
        loader.hide();
      });
      },
    
      removeShowEventClicked(){
        this.EventShowDetailModal = false;
        this.confirmDelete=true;
      }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('events.title')" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center">
                    <div class="ms-3 flex-grow-1 mb-2">
                      <div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="pe-2">{{ f.badge }}</span>
                            <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col text-sm-end d-flex justify-content-end" v-if="showHost">
                  <ul class="nav nav-pills product-view-nav interact me-2" v-on:click="onFilterClicked">
                    <li class="nav-item">
                      <a class="nav-link active" href="javascript: void(0);">
                        <i class="bx bx-filter align-middle"></i>
                      </a>
                    </li>
                  </ul>
                  <b-dropdown variant="primary" >
                      <template v-slot:button-content>
                        <i class="mdi mdi-plus me-1"></i> {{$t('events.new_event')}}
                      </template>
                      <b-dropdown-item v-on:click="onCreateEventClicked"> <i class="mdi mdi-video me-1"></i> {{$t("events.event_new_call")}}</b-dropdown-item>
                      <b-dropdown-item v-on:click="onCreateNewShowClicked"><i class="mdi mdi-video-vintage me-1"></i> {{$t("events.event_new_show")}}</b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class=" col text-sm-end" v-else>
                    <button
                        type="button"
                        class="btn btn-primary mb-2 me-2"
                        @click="onCreateEventClicked">
                    <i class="mdi mdi-plus me-1"></i> {{$t('events.new_event')}}
                  </button>
                </div>
              
              </div>
            </div>
            <div class="app-calendar">
              <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
                :key="calendarKey"
                :events="events">
                  <template v-slot:eventContent='arg'>
                    <b>{{ arg.timeText + " " }}</b>
                    <i>{{ arg.event.title }}</i>
                  </template>
              </FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
   <b-modal
      v-model="showModalCreateEvent"
      :title= " event._id ? $t('events.update_event') : $t('events.new_event')"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <NewEventModal @createEvent="handleCreateEvent"  @updateEvent="handleUpdateEvent" @closeCreateEventModal="hideModal" :showDate="showDate" :eventInfo="newEventData" :event="event" :showHost="showHost"/>
    </b-modal>

    <b-modal
      v-model="showModalEventDetail"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
      hide-header>
      <EventDetailModal @editEventClicked="editEventClicked" @closeEventDetailModal="hideEventDetailModal" @removeEventClicked="removeEventClicked" :event="event" @assignEventClicked="assignEventClicked"/>
    </b-modal>

    <b-modal
      :title="event.title"
      v-model="showModalAssignEvent"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <div>
        <p>{{$t("events.events_available_hosts") }}</p>

        <div class="col-8 mb-3 text-end">
          <multiselect 
            v-model="selectedHost"
            :options="hostsAvailable"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            :placeholder="$t('events.events_available_host_placeholder')"
            :multiple="false"
            track-by="_id" 
            label="name"
            :showNoResults="true"
            open-direction="bottom">
          </multiselect>
        </div>

        <div class="text-end pt-2 mt-3">
          <b-button variant="primary" class="ms-1" @click="handleAssignEventClicked">{{$t('common.accept')}}</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveEventClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <p>{{$t('events.remove_event')}}</p>
    </b-modal>

    <b-modal
      v-model="showNewShowModal"
      :title= "event._id ? $t('events.edit_show'): $t('events.new_show')"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer>
      <NewShowEventModal @createShowEvent="handleCreateShowEvent"  @updateShowEvent="handleUpdateShowEvent" @closeCreateShowEventModal="hideShowModal" :showDate="showDate" :eventInfo="newEventData" :event="event" :showHost="showHost"/>
    </b-modal>

    <b-modal
      v-model="showModalShowEventDetail"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
      hide-header>
      <EventShowDetailModal @editEventClicked="editEventShowClicked" @closeEventDetailModal="hideEventShowDetailModal" :event="event" @removeEventClicked="removeShowEventClicked"/>
    </b-modal>

    <Drawer
      @close="rightDrawer"
      :align="'right'"
      :closeable="true"
      :maskClosable="true"
      :zIndex="1002"
      class="h-100">
      <div v-if="openRightDrawer" class="h-100">
          <div class="offcanvas-header">
              <h4 class="offcanvas-title" id="offcanvasExampleLabel">
              <i class="bx bx-gear font-size-16 align-middle me-1"></i>
              {{$t('filter.title')}}
              </h4>
          </div>
          <hr class="mt-1" />
          <div class="offcanvas-body flex-grow-1 h-100">
            <div>
              <FilterEvents ref="filterRef"></FilterEvents>
            </div>
            <div class="position-fixed bottom-0 end-0 p-3">
              <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
              <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
            </div>
          </div>
        </div>
    </Drawer>
  </Layout>
</template>

<style> 

.text-decoration-line-through{
  text-decoration: line-through;
  
}

.text-pre-wrap{
  white-space: pre-wrap;
}

.dropdown-toggle {
  padding-top: 5px !important;
}

</style>
