<script>
import Multiselect from "vue-multiselect";

import {
  campaignMethods,
  userMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      hosts:[],
      campaigns:[],
      status:[
        {
            id: 'scheduled',
            name: this.$t('events_list.status_scheduled')
        },
        {
            id: 'started',
            name: this.$t('events_list.status_started')
        },
        {
            id: 'canceled',
            name: this.$t('events_list.status_canceled')
        },
        {
            id: 'missed',
            name: this.$t('events_list.status_missed')
        },
        {
            id: 'closed',
            name: this.$t('events_list.status_closed')
        }
      ],
      selectedCampaign:null,
      selectedHost:null,
      selectedStatus: null,
      selectedGuest:null,
      filters:[]
    };
  },
  components:{Multiselect},
  props: {
    
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    ...campaignMethods,
    ...userMethods,

    loadCampaigns(){
      let loader = this.$loading.show();
      const params={
        q: `limit=200`,
      }

      params.q+= `&where[project]=${localStorage.getItem('current_project')}` 
      
    this.getCampaigns(params).then((campaigns)=>{
        if(campaigns&& campaigns.data){
          this.campaigns= campaigns.data;
          this.selectedCampaign = this.filters.filter(f=> f.key== 'campaign').length > 0 ? this.campaigns.filter(g=> g._id == this.filters.filter(f=> f.key== 'campaign')[0].value)[0]  :null
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("campaigns.get_campaigns_error"),title:  this.$t("campaigns.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    loadUsers(){
        this.isLoading = true;
        let loader = this.$loading.show();
        const params={
            q: `role=host&limit=1000&select=_id name email`,
        }
        this.getUsers(params).then((users)=>{
            if(users&& users.data){
                this.hosts= users.data;
                this.selectedHost = this.filters.filter(f=> f.key== 'host').length > 0 ? this.hosts.filter(g=> g._id == this.filters.filter(f=> f.key== 'host')[0].value)[0]  :null
            }
        }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title") });
        }).finally(()=>{
            this.isLoading = false;
            loader.hide();
        });
    },

    removeAll(){
      this.selectedHost = null;
      this.selectedCampaign = null;
      this.selectedStatus = null;
      this.selectedGuest = null;
    },
    getAllFilters(){
      const filters= [];

      if(this.selectedStatus){
        filters.push({
          key: 'status',
          value: this.selectedStatus.id,
          badge: `${this.$t("events.filter_status")}: ${this.selectedStatus.name}`
        });
      }

      if(this.selectedCampaign){
        filters.push({
          key: 'campaign',
          value: this.selectedCampaign._id,
          badge: `${this.$t('filter.campaign')}: ${this.selectedCampaign.name}`
        });
      }

      if(this.selectedHost){
        filters.push({
          key: 'host',
          value: this.selectedHost._id,
          badge: `${this.$t("events.filter_host")}: ${this.selectedHost.name}`
        });
      }
      
      if(this.selectedGuest){
        filters.push({
          key: 'guest',
          value: this.selectedGuest,
          badge: `${this.$t("events.filter_guest")}: ${this.selectedGuest}`
        });
      }
      
      return filters;
    },
    setAllFilter(filters){
      
      this.selectedStatus = filters.filter(f=> f.key == 'status').length > 0 ? this.status.filter(g=> g.id == filters.filter(f=> f.key== 'status')[0].value)[0]  :null;
      this.selectedGuest = filters.filter(f=> f.key == 'guest').length > 0 ? filters.filter(f=> f.key == 'guest')[0].value :'';

      this.filters = filters;
      
      this.loadCampaigns();
      this.loadUsers();
    }
  },
  watch:{
    
  }
};
</script>

<template>
  <div class="flex-grow-1 h-100 accordion" role="tablist">
    <div class="mb-2">
      <div class="badge bg-primary m-1" v-if="selectedStatus">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t("events.filter_status")}}: {{selectedStatus?.name}}</span>
              <i v-on:click="selectedStatus =null" class="fa fa-times interact "></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="selectedCampaign">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.campaign')}}: {{selectedCampaign?.name}}</span>
              <i v-on:click="selectedCampaign =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="selectedHost">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t("events.filter_host")}}: {{selectedHost?.name}}</span>
              <i v-on:click="selectedHost =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="selectedGuest">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t("events.filter_guest")}}: {{selectedGuest}}</span>
              <i v-on:click="selectedGuest=null" class="fa fa-times interact"></i>
          </div>
      </div>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-0'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t("events.filter_status")}}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-0`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t("events.filter_status_subtitle") }}</p>
            <multiselect 
              :options="status"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="selectedStatus"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-1'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.campaign')}}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-1`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t('filter.campaign_subtitle') }}</p>
            <multiselect 
              :options="campaigns"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="selectedCampaign"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-2'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t("events.filter_host")}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-2`" role="tabpanel" class="mt-2">
          <p class="small mb-1">{{$t("events.filter_host_subtitle")}}</p>
          <multiselect 
              :options="hosts"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="selectedHost"
              :allow-empty="false">
            </multiselect>
        </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-3'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t("events.filter_guest")}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-3`" role="tabpanel" class="mt-2">
          <p class="small mb-1">{{$t("events.filter_guest_subtitle")}}</p>
          <b-form-input
              v-model="selectedGuest"
              type="text">
          </b-form-input>
        </b-collapse>
    </div>
  </div>
</template>
