<script>

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";

import {
  campaignMethods,
  userMethods
} from "@/state/helpers";

/**
 * New event component
 */
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      submitted:false,
      campaigns:[],
      hosts:[],
      date:null,
      time:null
    };
  },
  validations: {
    event: {
      title: { required },
      campaign: { required },
      
      host: { required },
      date: { required },
      duration:{required},
      time:{required},
    },
  },
  methods: {
    ...campaignMethods,
    ...userMethods,
    
    onCreateOrEditEventClicked() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } 

      if(this.event._id) {
        this.$emit('updateShowEvent');
      }else{
        this.$emit('createShowEvent');
      }
    },

    loadCampaigns(){
      let loader = this.$loading.show();
      this.getCampaigns().then((campaigns)=>{
        if(campaigns&& campaigns.data){
          this.campaigns= campaigns.data;
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("campaigns.get_campaigns_error"),title:  this.$t("campaigns.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    onCampaignSelected(){
      this.hosts = [];
      if(this.event.campaign){
        this.loadHostsByCampaign(this.event.campaign);
        this.event.duration = this.event.campaign.durationHour* 60 + this.event.campaign.durationMinutes;
      }
    },

    loadHostsByCampaign(campaign){
      let loader = this.$loading.show();
      const params={
				q: `where[role]=host&where[group]=${campaign.group}`,
			}
			this.getUsers(params).then((users)=>{
				if(users&& users.data){
					this.hosts= users.data;
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
    },

    dateChanged(){
      this.event.date= this.date;
    },

    timeChanged(){
      this.event.time= this.time;
    }
  },



  mounted() {
    this.loadCampaigns();
    
    if(JSON.parse(localStorage.getItem('auth.currentUser')).user.role=='host') {
      this.event.host = JSON.parse(localStorage.getItem('auth.currentUser')).user;
    }
    if(this.event._id) {
      this.date = this.event.date;
      this.time = this.event.time;

      this.hosts = [];
      if(this.event.campaign){
        this.loadHostsByCampaign(this.event.campaign);
      }
      
    }
    
    if(this.eventInfo && this.eventInfo.date){
      this.date= new Date(this.eventInfo.date);
      this.dateChanged();
    }

  },
  props: {
    eventInfo: {
      type: Object,
    },
    showHost: {
      type: Boolean,
    },
    showDate: {
      type: Boolean,
    },
    event:{
      type: Object,
    },
    eventId:{
      type: String,
    },

  },
  emits: ['createShowEvent','updateShowEvent','closeCreateShowEventModal']
  
};
</script>

<template>
    <div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label for="title">{{$t('events.new_event_title')}}</label>
          <input
            id="title"
            v-model="event.title"
            type="text"
            class="form-control"
            :placeholder="$t('events.new_event_title_placeholder')"
            :class="{ 'is-invalid': submitted && $v.event.title.$error }"
          />
          <div v-if="submitted && !$v.event.title.required" class="invalid-feedback">
            {{$t('events.new_event_title_required')}}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label class="control-label">{{$t('events.new_event_campaign')}}</label>
          <multiselect id="campaign"
              v-model="event.campaign"
              :options="campaigns"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :placeholder="$t('events.new_event_campaign_placeholder')"
              :multiple="false"
              :class="{'is-invalid': submitted && $v.event.campaign.$error,}"
              track-by="name" 
              label="name"
              :showNoResults="false"
              @input="onCampaignSelected">
          </multiselect>
          <div v-if="submitted && !$v.event.campaign.required" class="invalid-feedback">
            {{$t('events.new_event_campaign_required')}}
          </div>
        </div>
      </div>
      <div class="col-12" v-if="showHost">
        <div class="mb-3">
          <label class="control-label">{{$t('events.new_event_host')}}</label>
          <multiselect 
              v-model="event.host"
              :options="hosts"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :placeholder="$t('events.new_event_host_placeholder')"
              :multiple="false"
              :class="{'is-invalid': submitted && $v.event.host.$error,}"
              track-by="name" 
              label="name"
              :showNoResults="false">
          </multiselect>
          <div v-if="submitted && !$v.event.host.required" class="invalid-feedback">
            {{$t('events.new_event_host_required')}}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label>{{$t('events.new_event_date')}}</label>
        <date-picker
          ref="date"
          v-model="date"
          :first-day-of-week="1"
          :placeholder="$t('events.new_event_date_placeholder')"
          :class="{'is-invalid': submitted && $v.event.date.$error,}"
          @input="dateChanged">
        </date-picker>
        <div v-if="submitted && !$v.event.date.required"  class="invalid-feedback">
          {{$t('events.new_event_date_required')}}
        </div>
      </div>
      <div class="mb-3 col-sm-8">
        <label>{{$t('events.new_event_hour')}}</label>
        <date-picker
          v-model="time"
          format="hh:mm a"
          value-type="format"
          type="time"
          :placeholder="$t('events.new_event_hour_placeholder')"
          :class="{'is-invalid': submitted && $v.event.time.$error,}"
          @input="timeChanged"
        ></date-picker>
        <div v-if="submitted && !$v.event.time.required" class="invalid-feedback">
          {{$t('events.new_event_hour_required')}}
        </div>
      </div>

      <div class="mb-3 col-sm-4">
        <label>{{$t('events.new_event_duration')}}</label>
        <div class="form-group">
          <b-form-input
            v-model="event.duration"
            type="number"
            name="number"
            :class="{'is-invalid': submitted && $v.event.duration.$error,}">
          </b-form-input>
        </div>
        <div v-if="submitted && !$v.event.duration.required" class="invalid-feedback">
          {{$t('events.new_event_duration_required')}}
        </div>
      </div>
    </div>
    <div class="text-end pt-5 mt-3">
      <b-button variant="light" @click="$emit('closeCreateShowEventModal')">{{$t('common.cancel')}}</b-button>
      <b-button variant="primary" class="ms-1" @click="onCreateOrEditEventClicked">{{$t('common.confirm')}}</b-button>
    </div>
  </div>
</template>