<script>

export default {
  components: {
  },
  data() {
    return {
      dateStr:null
    };
  },
  
  methods: {

    onVideoCallClicked(){
      window.open(this.event.urlHost);
    },
    
    addMinutes(numOfMinutes, date) {
      date.setTime(date.getTime() + numOfMinutes  * 60 * 1000);
      return date;
    },
  
    formatHourAndMinutes() {
      let hour = this.event.date.getHours();
      let minutes = this.event.date.getMinutes();

      if (hour.toString().length < 2) 
        hour = '0' + hour;

      if (minutes.toString().length < 2) 
        minutes = '0' + minutes;

      let endDate = this.addMinutes(this.event.duration,this.event.date);

      let hourEnd = endDate.getHours();
      let minutesEnd = endDate.getMinutes();

      var ampm = hourEnd >= 12 ? 'pm' : 'am';

      if (hourEnd.toString().length < 2) 
        hourEnd = '0' + hourEnd;

      if (minutesEnd.toString().length < 2) 
        minutesEnd = '0' + minutesEnd;

      return hour+":"+minutes +" - " + hourEnd+":"+minutesEnd + " " + ampm;
    },
},

  mounted() {
    if(this.event){
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      this.i18
      this.dateStr = this.$i18n.locale === 'es'? this.event.date.toLocaleString('es',options) : this.event.date.toLocaleString('en-US',options) ;
      this.dateStr+= "   .   "+this.formatHourAndMinutes()
    }
  },
  props: {
    event:{
      type: Object,
    },

  },
  emits: ['editEventClicked','closeEventDetailModal','removeEventClicked']
  
};
</script>

<template>
  <div>
    <div>  
      <h4 class = "col mb-0"> {{event.title}}</h4>
      <p> {{dateStr}}</p>
    <div class="top-right-menu" v-if="event.status != 'closed'">
      <b-dropdown
          class="card-drop"
          variant="white"
          right
          toggle-class="p-0"
          menu-class="dropdown-menu-end">
          <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical font-size-18"></i>
          </template>

          <b-dropdown-item @click="$emit('editEventClicked')" v-if="event.status=='scheduled' || event.status == 'started'">
          <i class="fas fa-edit text-success me-1"></i>
          {{$t('common.edit')}}
          </b-dropdown-item>

          <b-dropdown-item @click="$emit('removeEventClicked')" v-if="event.status=='scheduled'">
            <i class="fas fa-trash-alt text-danger me-1"></i>
            {{$t('common.delete')}}
          </b-dropdown-item>
      </b-dropdown>
    </div>
    <hr />
    </div>
    <div class = "col mb-3" v-if="event.status == 'started' || event.status == 'scheduled'" >
      <i :class="`font-size-20 bx bx-video-recording me-3 align-middle`"></i>
      <b-button variant="primary" class="w-lg" @click="onVideoCallClicked"> {{$t('events.start_show')}}</b-button>
    </div>
    <div class = "col mb-3 text-center font-weight-bold" v-if="event.status != 'started' && event.status != 'scheduled'" style="font-weight: bold;" >
      {{ event.status == 'closed' ? $t('events.event_detail_closed'): $t('events.event_detail_canceled')  }}
    </div>
    <div class="col mb-3">
      <i :class="`font-size-20 bx bx-briefcase-alt-2 me-3 align-middle`"></i>
        {{this.event.campaign.name}} 
    </div>
    <div class="col mb-3">
      <i :class="`font-size-20 bx bx-calendar me-3 align-middle`"></i>
        {{this.event.host.name}}
    </div>
    <div class="text-end pt-2 mt-3">
      <b-button variant="primary" class="ms-1" @click="$emit('closeEventDetailModal')">{{$t('common.accept')}}</b-button>
    </div>

  </div>
  
</template>

<style scoped>
.top-right-menu {
    position: absolute;
    top: 10px;
    right: 10px;
}

.icons{
  float: right;
  cursor: pointer;
}
</style>